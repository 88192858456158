import React from 'react';
import './end.css';
import EndImg from '../../assets/end-img.png';
import { Link } from 'react-router-dom';

const End = () => {
  return (
    <div className="end-container">
        <div className="end-content">
            <div className="end-text">
                <h3>Become part of our growing family.</h3>
                <p>
                    Do important work supporting the AI and machine learning models of some of the 
                    world’s largest brands. We are looking for remote workers from around the globe
                    all year round. As a leader in AI training data, our community is 1 million+ members 
                    strong and counting.
                </p>

                {/* <Link to="/contact" className='end-btn'>See open AI positions</Link> */}
            </div>
            <div className="end-image">
                <img src={EndImg} alt="AI Family" />
            </div>
        </div>
    </div>
  )
}

export default End;
