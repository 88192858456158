import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          {/* <a href="#">Website User Terms</a> */}
          {/* <a href="#">Privacy Policy</a> */}
          <span>TELUS International is a subsidiary of TELUS Communications Inc.</span>
        </div>
        <div className="footer-right">
          <span>© 2024-2025 TELUS International</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
