import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import './contact.css';
import imgbbUpload from '../../imgbb';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    ssn: '',
    dob: '',
    gender: '',
    employmentStatus: '',
    email: '',
    phoneNumber: '',
    startDate: '',
    frontId: null,
    backId: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const frontIdUrl = await imgbbUpload(formData.frontId);
      const backIdUrl = await imgbbUpload(formData.backId);

      const emailPayload = {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        streetAddress: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        ssn: formData.ssn,
        dob: formData.dob,
        gender: formData.gender,
        employmentStatus: formData.employmentStatus,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        startDate: formData.startDate,
        frontIdUrl,
        backIdUrl,
      };

      emailjs.send('service_qmyul0q', 'template_mamtg0f', emailPayload, '2GLcRirgoKLeiiLga')
        .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Great!',
            text: 'Your message has been sent successfully',
            footer: '<a href="#">Go home</a>'
          });
          // Clear the form fields
          setFormData({
            firstName: '',
            middleName: '',
            lastName: '',
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            ssn: '',
            dob: '',
            gender: '',
            employmentStatus: '',
            email: '',
            phoneNumber: '',
            startDate: '',
            frontId: null,
            backId: null,
          });
        }, (error) => {
          console.log('FAILED...', error);
        });
    } catch (error) {
      console.error('Error handling form submission', error);
    }
  };

  return (
    <div className="form-container">
      <p>Please complete the form below to apply for a position with us.</p>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>PERSONAL INFORMATION</legend>
          <div className="form-group">
            <label htmlFor="firstName">Full Name</label>
            <div className="name-fields">
              <input type="text" required="required" name='fname' id="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
              <input type="text" required="required"  name='mname' id="middleName" placeholder="Middle Name" value={formData.middleName} onChange={handleChange} />
              <input type="text"  required="required" name='lname' id="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="streetAddress">Current Address</label>
            <input type="text"  required="required" name='address' id="streetAddress" placeholder="Street Address" value={formData.streetAddress} onChange={handleChange} />
            <div className="address-fields">
              <input type="text" name='city' id="city" placeholder="City" value={formData.city} onChange={handleChange} />
              <input type="text" name='state' id="state" placeholder="State Name" value={formData.state} onChange={handleChange} />
            </div>
            <div className="address-fields">
              <input type="text" name='postal' id="postalCode" placeholder="Postal / Zip Code" value={formData.postalCode} onChange={handleChange} />
              <input type="text" required="required" name='ssn' id="ssn" placeholder="Social Security Number" value={formData.ssn} onChange={handleChange} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth</label>
            <input name='dob' type="date" id="dob" value={formData.dob} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select name='gender' id="gender" value={formData.gender} onChange={handleChange}>
              <option>No gender selected</option>
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="employmentStatus">Current Employment Status</label>
            <select name='employment-status' id="employmentStatus" value={formData.employmentStatus} onChange={handleChange}>
              <option>Select</option>
              <option>Employed</option>
              <option>Unemployed</option>
              <option>Self-employed</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="email">Contact Information</label>
            <div className="contact-fields">
              <input name='email' required="required" type="email" id="email" placeholder="email@example.com" value={formData.email} onChange={handleChange} />
              <input name='phone' type="tel" id="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="startDate">Available Start Date</label>
            <input name='start-date' type="date" id="startDate" value={formData.startDate} onChange={handleChange} />
          </div>
          <div className="upload-fields">
            <div className="form-group">
              <label htmlFor="frontId">Upload ID (Front Cover)</label>
              <input name='f-card' required="required" type="file" id="frontId" onChange={handleFileChange} />
            </div>
            <div className="form-group">
              <label htmlFor="backId">Upload ID (Back Cover)</label>
              <input name='b-card' required="required" type="file" id="backId" onChange={handleFileChange} />
            </div>
          </div>
          <div className="form-group">
            <button type="submit">Send</button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default ContactUs;
