import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import WhyJoin from './components/Why/WhyJoin';
import Opportunities from './components/Opportunities/Opportunities';
import Community from './components/End/End';
import Footer from './components/Footer/Footer';
import ContactUs from './pages/contact/contact'; // Ensure the correct path and name
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <WhyJoin />
              <Opportunities />
              <Community />
              <Footer />
            </>
          } />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
