import React from 'react';
import './whyjoin.css';
import WhyImage from "../../assets/why.png";
import Pay from '../../assets/pay.png';
import Support from '../../assets/support.png';
import Flexibility from '../../assets/flexibility.png';
import Diversity from '../../assets/diversity.png';
import Diverse from '../../assets/diverse.png';
import Secure from '../../assets/secure.jpg';

const WhyJoin = () => {
  return (
    <section className='why-join-section'>
        <div className="community-container">
            <div className="image-container">
                <img src={WhyImage} alt="Person working" className="community-image" />
            </div>
            <div className="text-container">
                <h2>Why join our Remote Community?</h2>
                <p>
                TELUS International supports a global community of 1 million+ members who help our
                customers collect, enhance, train, translate, and localize their content to build better
                AI models. Our AI Community is diverse, talented, and vibrant. We welcome people of all
                backgrounds, capabilities, and circumstances - and we remain deeply committed to
                fostering a fair, equal, and healthy environment in which all community members can thrive.
                </p>
            </div>
        </div>

    <div className="reasons-container">
        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Flexibility} alt="" />
            </div>
            <div className="reason-text">
                <h3>Engagement</h3>
                <p>
                    An active and engaged community is a better and healthier community. 
                    We will be responsive, communicate often and solicit regular feedback 
                    from our AI Community members.
                </p>
            </div>
        </div>

        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Diverse} alt="" />
            </div>
            <div className="reason-text">
                <h3>Engagement</h3>
                <p>
                    An active and engaged community is a better and healthier community. 
                    We will be responsive, communicate often and solicit regular feedback 
                    from our AI Community members.
                </p>
            </div>
        </div>

        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Diversity} alt="" />
            </div>
            <div className="reason-text">
                <h3>Diversity and opportunity</h3>
                <p>
                    We offer access to a wide range of opportunities for people of all backgrounds. 
                    We are proudly inclusive and diverse.
                </p>
            </div>
        </div>

        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Pay} alt="" />
            </div>
            <div className="reason-text">
                <h3>Fair treatment and pay</h3>
                <p>
                    We aim to treat everyone in our community equally and with respect. 
                    We offer fair rates for all of our projects across all of our markets.
                </p>
            </div>
        </div>

        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Secure} alt="" />
            </div>
            <div className="reason-text">
                <h3>Excellence and integrity</h3>
                <p>
                    We strive to be the leader in security, confidentiality and data privacy 
                    for both our customers and our community.
                </p>
            </div>
        </div>

        <div className="reasons-box">
            <div className="reason-img-box">
                <img src={Support} alt="" />
            </div>
            <div className="reason-text">
                <h3>Support</h3>
                <p>
                    Our job is to set you up for success. We provide the necessary information and tools 
                    to ensure you can complete your work to achieve the high standards set by our customers.
                </p>
            </div>
        </div>
    </div>
    </section>
    
  );
};

export default WhyJoin;
