import React from 'react';
import Logo from '../../assets/logo.png';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <div className="top-header">
        <div className="logo">
          <img src={Logo} alt="TELUS International Logo" />
        </div>
        {/* <div className="nav">
          <a href="#investor-relations">Investor Relations</a>
          <a href="#language">EN</a>
        </div> */}
      </div>
      <div className="section-title">
        <h2>Remote Positions</h2>
      </div>
      <div className="banner">
        <div className="content">
          <h1>Submit Your Information to Move Forward!</h1>
          <Link to="/contact" className="header-cta-button">Submit Your Info</Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
