import React from 'react';
import './opportunities.css';
import Image1 from '../../assets/img1.png';
import Image2 from '../../assets/img2.png';
import Image3 from '../../assets/img3.jpg';
import Image4 from '../../assets/img4.jpg';

const Opportunities = () => {
  return (
    <section className="opportunities">
      <h2>Our Remote Community <br /> opportunities</h2>

      <div className="opportunities-box">
          <div className="opportunities-box-img">
            <img src={Image1} alt="Data annotation jobs" />
          </div>
          <div className="opportunities-box-text">
            <h3>Data annotation jobs</h3>
            <p>
              By creating and annotating various types of data, you will contribute to the future of artificial 
              intelligence with every project you complete. Common tasks include labeling objects in images, 
              labeling words in bodies of text, labeling voice recordings and more. As a member of our AI Community, 
              you will be thoroughly trained to handle each and every project.
            </p>
          </div>
        </div>

      <div className="opportunities-box">
          <div className="opportunities-box-img">
            <img src={Image2} alt="Data annotation jobs" />
          </div>
          <div className="opportunities-box-text">
            <h3>Data enrichment jobs</h3>
            <p>
              By creating and annotating various types of data, you will contribute to the future of artificial 
              intelligence with every project you complete. Common tasks include labeling objects in images, 
              labeling words in bodies of text, labeling voice recordings and more. As a member of our AI Community, 
              you will be thoroughly trained to handle each and every project.
            </p>
          </div>
        </div>

        <div className="opportunities-box">
          <div className="opportunities-box-img">
            <img src={Image3} alt="Data annotation jobs" />
          </div>
          <div className="opportunities-box-text">
            <h3>Online rating jobs</h3>
            <p>
              By creating and annotating various types of data, you will contribute to the future of artificial 
              intelligence with every project you complete. Common tasks include labeling objects in images, 
              labeling words in bodies of text, labeling voice recordings and more. As a member of our AI Community, 
              you will be thoroughly trained to handle each and every project.
            </p>
          </div>
        </div>

        <div className="opportunities-box">
          <div className="opportunities-box-img">
            <img src={Image4} alt="Data annotation jobs" />
          </div>
          <div className="opportunities-box-text">
            <h3>Online maps and data analysis</h3>
            <p>
              By creating and annotating various types of data, you will contribute to the future of artificial 
              intelligence with every project you complete. Common tasks include labeling objects in images, 
              labeling words in bodies of text, labeling voice recordings and more. As a member of our AI Community, 
              you will be thoroughly trained to handle each and every project.
            </p>
          </div>
        </div>
    </section>
  );
}

export default Opportunities;
