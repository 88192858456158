// imgbb.js
import axios from 'axios';

const imgbbUpload = async (file) => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
      params: {
        key: '560966227bff60cc0704880bd5ab1c91', // Replace with your ImgBB API key
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data.data.url;
  } catch (error) {
    console.error('Error uploading to ImgBB:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default imgbbUpload;
